import React, { lazy, Suspense } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { connect } from 'react-redux'

import Layout from './layouts'

const projectName = "labelle"

const routes = [
  // test
  { path: '/testList', Component: lazy(() => import('pages_custom/test/list'))},
  { path: '/test/:id', Component: lazy(() => import('pages_custom/test/edit'))},
  { path: '/test_normal', Component: lazy(() => import('pages_custom/test_normal/list'))},
  // { path: '/test_full', Component: lazy(() => import('pages_custom/test_full/list'))},
  // dynamic
  { path: '/devInit', Component: lazy(() => import('pages_custom/zystem/devInit'))},
  { path: '/masterList', Component: lazy(() => import('pages_custom/master/list'))},
  { path: '/master/:id', Component: lazy(() => import('pages_custom/master/edit'))},
  // user
  // { path: '/userList', Component: lazy(() => import('pages_custom/user/list'))},
  // { path: '/user/:id', Component: lazy(() => import('pages_custom/user/edit'))},
  // Custom
  { path: '/userMyProfile', Component: lazy(() => import('pages_custom/userMyProfile')), exact: true,},

  // RIS PROJECT ///////////////////////////////////////////////////////////////
  // my
  { path: '/myCredit', Component: lazy(() => import('app/labelle/pages/my/myCredit'))},
  // user
  { path: '/userList', Component: lazy(() => import('app/labelle/pages/user/list'))},
  { path: '/user/:id', Component: lazy(() => import('app/labelle/pages/user/edit'))},
  // user
  { path: '/userPointList', Component: lazy(() => import('app/labelle/pages/userPoint/list'))},
  { path: '/userPoint/:id', Component: lazy(() => import('app/labelle/pages/userPoint/edit'))},
  // // service
  // { path: '/serviceList', Component: lazy(() => import('app/labelle/pages/service/list'))},
  // { path: '/service/:id', Component: lazy(() => import('app/labelle/pages/service/edit'))},
  // product
  { path: '/productList', Component: lazy(() => import('app/labelle/pages/product/list'))},
  { path: '/product/:id', Component: lazy(() => import('app/labelle/pages/product/edit'))},
  // productGroup
  { path: '/productGroupList', Component: lazy(() => import('app/labelle/pages/productGroup/list'))},
  { path: '/productGroup/:id', Component: lazy(() => import('app/labelle/pages/productGroup/edit'))},
  // saleProduct
  { path: '/saleProductList/:id', Component: lazy(() => import('app/labelle/pages/saleProduct/list'))},
  { path: '/saleProductList',     Component: lazy(() => import('app/labelle/pages/saleProduct/list'))},
  { path: '/saleProduct/:id', Component: lazy(() => import('app/labelle/pages/saleProduct/edit'))},
  { path: '/saleProductView/:id', Component: lazy(() => import('app/labelle/pages/saleProduct/view'))},
  // saleProductSharing
  { path: '/saleProductSharingList/:transaction_id', Component: lazy(() => import('app/labelle/pages/saleProductSharing/list'))},
  { path: '/saleProductSharingList', Component: lazy(() => import('app/labelle/pages/saleProductSharing/list'))},
  { path: '/saleProductSharing/:id', Component: lazy(() => import('app/labelle/pages/saleProductSharing/edit'))},
  { path: '/mySaleProductSharingList', Component: lazy(() => import('app/labelle/pages/saleProductSharing/myList'))},

  // Auth Pages
  {
    path: '/auth/login',
    Component: lazy(() => import('pages/auth/login')),
    exact: true,
  },
  {
    path: '/auth/forgot-password',
    Component: lazy(() => import('pages/auth/forgot-password')),
    exact: true,
  },
  {
    path: '/auth/register',
    Component: lazy(() => import('pages/auth/register')),
    exact: true,
  },
  {
    path: '/auth/lockscreen',
    Component: lazy(() => import('pages/auth/lockscreen')),
    exact: true,
  },
  {
    path: '/auth/404',
    Component: lazy(() => import('pages/auth/404')),
    exact: true,
  },
  {
    path: '/auth/500',
    Component: lazy(() => import('pages/auth/500')),
    exact: true,
  },
]

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation,
})

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      { window.location.href.indexOf("/sale/")>-1
      ?
      <div className="c-layout-page">
        {/* <Route exact path="/web/:id" component={WebShow}/> */}
        {routes.map(({ path, Component, exact }) => (
          <Route
            path={path}
            key={path}
            exact={exact}
            render={() => {
              return (
                <div className={routerAnimation}>
                  <Suspense fallback={null}>
                    <Component />
                  </Suspense>
                </div>
              )
            }}
          />
        ))}
      </div>
      :
      <Layout>
        <Route
          render={state => {
            const { location } = state
            return (
              <SwitchTransition>
                {/* {console.log(location)} */}
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === 'none' ? 0 : 300}
                >
                  <Switch location={location}>
                    {/* <Route exact path="/" render={() => <Redirect to="/dashboard/analytics" />} /> */}
                    <Route exact path="/" render={() => <Redirect to="/userMyProfile" />} />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={(props) => {
                          return (
                            <div className={routerAnimation} >
                              <Suspense fallback={null} >
                                {/* props.match.params = {JSON.stringify(props.match.params)} */}
                                <Component props={props} />
                              </Suspense>
                            </div>
                          )
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            )
          }}
        />
      </Layout>
      }
    </ConnectedRouter>
  )
}
export default connect(mapStateToProps)(Router)