import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import firebaseConfig from './firebaseConfig_labelle'

// fb.database.ServerValue.TIMESTAMP
// fb.firestore.FieldValue.serverTimestamp()

firebase.initializeApp(firebaseConfig)
export const fb = firebase;
export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
export const storage = firebase.storage()

export async function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, firstName, lastName, mobile) {
  return registerWithRole(email, password, firstName, lastName, mobile, "member")
}
export async function registerWithRole(email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        db.collection("user").doc(uid).set({
          uid: uid,
          "role": role,
          firstName, 
          lastName, 
          mobile,
          email,
          createDate: fb.firestore.FieldValue.serverTimestamp(),
        })
        // rdb
        //   .ref('user')
        //   .child(uid)
        //   .set({
        //     "role": role,
        //     firstName, 
        //     lastName, 
        //     mobile,
        //     email,
        //     createDate: fb.firestore.FieldValue.serverTimestamp(),
        //   })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}
export async function createUserAccount(user_id, email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        // const snapshot = await firebase.firestore().collection('user').where("uid", "==", uid).get()
        // const result = snapshot.docs.map(doc => doc.data())
        // const userFields = result.length > 0 ? result[0] : null
        // if(userFields){

        // }
        db.collection("user").doc(user_id).update({
          uid: uid,
          updateDate: fb.firestore.FieldValue.serverTimestamp(),
        })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(authUser) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(authUser.currentUser)
      }
      const unsubscribe = authUser.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            // const userFields = await rdb
            //   .ref('user')
            //   .child(user.uid)
            //   .once('value')
            //   .then(snapshot => {
            //     return snapshot.val()
            //   })
            // const userFields2 = await db
            //   .collection('user')
            //   .doc(user.uid)
            //   .get()
            //   .then(snapshot => {
            //     console.log("login as ",JSON.stringify(snapshot.data()))
            //     return snapshot.data()
            //   })
            const snapshot = await firebase.firestore().collection('user').where("uid", "==", user.uid).get()
            const result = snapshot.docs.map(doc => doc.data())
            const resultkey = snapshot.docs.map(doc => doc.id)
            const key = result.length > 0 ? resultkey[0] : null
            const userFields = result.length > 0 ? result[0] : null
            console.log("current = ", userFields)
            console.log("key = ", key)
            if(userFields){
              const mergedUser = Object.assign(user, {
                // id: user.uid,
                // _uid: user.uid,
                id: key,
                firstName: userFields.firstName || '',
                lastName: userFields.lastName,
                nickName: userFields.nickName,
                mobile: userFields.mobile,
                role: userFields.role,
                // avatar: user.photoUrl,
                photo: userFields.photo,
  
               
                creditBalance: userFields.creditBalance,
                pointBalance: userFields.pointBalance,
              })
              return mergedUser
            }
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(auth)
}

export async function logout() {
  return auth.signOut().then(() => true)
}


export async function doGetUserProfile() {
  // db.collection("user")
  //   .onSnapshot(function(data){
  //     console.log("fetch "+"user"+" size = ", data.docs.length);
  // });
  const docRef = db.collection("user").doc("c");
  docRef.get().then(function func(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function (error) {
      console.log("Error getting document:", error);
  });
}

// auth ########################################################################

// Sign Up
// export const doCreateUserWithEmailAndPassword = (email, passwordone, n, u) =>
//   auth.createUserWithEmailAndPassword(email, passwordone).then(resp => {
//     return db
//       .collection("user")
//       .doc(u)
//       .set({
//         name: n,
//         username: u,
//         email: email,
//         creationDate: new Date().toISOString(),
//         userID: resp.user.uid
//       });
//   });

// export const checkUserNameAvalaibility = un =>
//   db
//     .doc(`/user/${un}`)
//     .get()
//     .then(doc => {
//       if (doc.exists) {
//         return false;
//       } else {
//         return true;
//       }
//     });

// export const doCreateUserWithEmailAndPassword = (email, passwordone, n, u) =>
//   db.doc(`/user/${u}`).get()
//   .then(doc => {
//       if(doc.exists){
//           return alert("user name already taken");
//       } else {
//           return auth().createUserWithEmailAndPassword(email, passwordone, n, u).then(resp => {
//                 return db
//                   .collection("user")
//                   .doc(u)
//                   .set({
//                     name: n,
//                     username: u,
//                     email: email,
//                     creationDate:new Date().toISOString(),
//                     userID:resp.user.uid,
//                   });
//               })
//       }
//   });

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// Sign Out
export const doSignOut = () => auth.signOut()

// // Password Reset
// export const doPasswordReset = email =>
//   auth
//     .sendPasswordResetEmail(email)
//     .then(function() {
//       // Password reset email sent.
//     })
//     .catch(function(error) {
//       // Error occurred. Inspect error.code.
//     });

// // Password Change
// export const doPasswordUpdate = password =>
//   auth.currentUser.updatePassword(password);

// //Email Update
// export const doEmailUpdate = email =>
//   auth.currentUser.updateEmail(email).then(
//     db
//       .collection("user")
//       .doc("user3") //retrieve user name from fb
//       .update({
//         email: email
//       })
//   );

// //Update nick name
// export const doNickNameUpdate = nickname =>
//   db
//     .collection("user")
//     .doc("c")
//     .update({
//       name: nickname
//     });

// export const doBioUpdate = newBio =>
//   db
//     .collection("user")
//     .doc(localStorage.getItem("username"))
//     .update({
//       bio: newBio
//     });
